<template>
  <div class="oldBrand-add">
    <GlobalInfoBar :title="titleDetail" content />
    <GlobalChunk :icon="DetailIMG" :title="titleDetail" :padding="[32, 0, 95, 55]">
      <el-row>
        <el-col :span="10">
          <GlobalForm
            :init-data="initData"
            :form-rules="formRules"
            :form-item-list="formItemList"
            @handleConfirm="handleConfirm"
            confirmBtnName="提交"
          >
            <el-button slot="btnSlot" @click="FromBack">返回</el-button>
          </GlobalForm>
        </el-col>
      </el-row>
    </GlobalChunk>
  </div>
</template>

<script>
import { formValidateMobile } from "@/utils/form-validate";
import _api from "@/utils/request";
export default {
  name: "oldBrand-add",
  data() {
    return {
      titleDetail:"",
      DetailIMG:"",
      StaffFormData: [],
      id: "",
      initData: {
        useType: "",
        title: "",
        viceTitle: "",
        appVideo: "",
        coverImg: ""
      },
      formItemList: [
        {
          key: "useType",
          type: "radio",
          labelName: "选择类型",
          option: [
            { label: "回收商家视频", value: "01" },
            { label: "门店商家视频", value: "02" }
          ]
        },
        {
          key: "title",
          type: "input",
          labelName: "视频标题",
          placeholder: "输入视频标题"
        },
        {
          key: "viceTitle",
          type: "input",
          labelName: "视频副标题",
          placeholder: "输入视频副标题"
        },
        {
          key: "appVideo",
          type: "input",
          labelName: "视频VID",
          placeholder: "输入视频VID"
        },

        {
          key: "coverImg",
          type: "upload",
          labelName: "视频封面",
          placeholder: "最多支持上传1张图片，大小不超过5M，格式为JPG或PNG"
        }
      ],

      formRules: {
        staffName: [
          {
            required: true,
            trigger: "blur",
            message: "请输入商家名称"
          }
        ],
        mobile: [
          {
            validator: formValidateMobile,
            trigger: "blur"
          }
        ]
      }
    };
  },
  created() {},

  mounted() {
    if (this.$route.query.type === "edit") {
      this.titleDetail = '编辑视频'
      this.DetailIMG = 'edit'
      _api.domeVideoInfo({ id: this.$route.query.id}).then(res => {
        if (res.code === 1) {
          this.StaffFormData = res.data;
          this.initData.useType = this.StaffFormData.useType;
          this.initData.title = this.StaffFormData.title;
          this.initData.viceTitle = this.StaffFormData.viceTitle;
          this.initData.appVideo = this.StaffFormData.appVideo;
          this.initData.coverImg = this.StaffFormData.coverImg;
          this.id = this.StaffFormData.id;
        }
      });
    }else{
      this.titleDetail = '添加视频'
      this.DetailIMG = 'add'
    }
  },

  methods: {
    handleConfirm(data, cd) {
      cd();
      console.log(data);
      const dto = {
        useType: data.useType,
        title: data.title,
        viceTitle: data.viceTitle,
        appVideo: data.appVideo,
        coverImg: data.coverImg,
        id: this.id
      };
      if (this.$route.query.type === "edit") {
        _api.updateDomeVideo(dto).then(res => {
          if (res.code === 1) {
            this.$router.push({ path: "/DemoVideo/list" });
            this.$message({
              message: res.msg,
              type: "success"
            });
          } else {
            this.$message({
              message: res.msg,
              type: "error"
            });
          }
        });
      } else {
        _api.addDomeVideo(dto).then(res => {
          if (res.code === 1) {
            this.$router.push({ path: "/DemoVideo/list" });
            this.$message({
              message: res.msg,
              type: "success"
            });
          } else {
            this.$message({
              message: res.msg,
              type: "error"
            });
          }
        });
      }
    },
    FromBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.oldBrand-add {
}
</style>
